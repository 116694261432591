import React, { useEffect }  from "react"
import styles from "./MapOverview.module.css"

const MapOverview = (props) => {
  const { map, onMapSelect, selectedId, color } = props;

  const BASE_URL = 'https://www.foodsecurityecosystem.com'

  return (
    <div className={styles.ecosustem_wrapper}>
      <a onClick={() => onMapSelect(map)}>
        {/* <div>{map.title}</div> */}
        <img src={BASE_URL + map.root_image_url} className={styles.ecosystem_image}/>
      </a>
    </div>
  );
}


export default MapOverview