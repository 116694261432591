import axios from 'axios';

const BASE_API_URL = '/api';

export const FETCH_COMPANY_BEGIN   = 'FETCH_COMPANY_BEGIN';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAILURE = 'FETCH_COMPANY_FAILURE';

export const SHOW_COMPANY_DRAWER   = 'SHOW_COMPANY_DRAWER';
export const HIDE_COMPANY_DRAWER = 'HIDE_COMPANY_DRAWER';

export const fetchCompanyBegin = () => ({
  type: FETCH_COMPANY_BEGIN
});

export const fetchCompanySuccess = (company) => ({
  type: FETCH_COMPANY_SUCCESS,
  payload: { company }
});

export const fetchCompanyFailure = error => ({
  type: FETCH_COMPANY_FAILURE,
  payload: { error }
});

export function fetchCompany(id, mapId) {
  return dispatch => {
    dispatch(fetchCompanyBegin());
    return axios.get(`${BASE_API_URL}/companies/${id}.json?map_id=${mapId}`)
      .then(json => {
        dispatch(fetchCompanySuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(fetchCompanyFailure(error.response.data)));
  };
}

export const showCompanyDrawer = () => ({
  type: SHOW_COMPANY_DRAWER
});

export const hideCompanyDrawer = () => ({
  type: HIDE_COMPANY_DRAWER
});
