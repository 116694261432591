const data = {
  nodes: [
    {
      id: 0,
      collapsed: true,
      child_links: [
        { source: 0, target: 1 },
        { source: 0, target: 5 },
        { source: 0, target: 9 },
        { source: 0, target: 12 },
        { source: 0, target: 18 },
      ],
      val: 54,
      name: "Root",
      type: "root",
      logo: "https://meshconomy.s3.us-east-2.amazonaws.com/9xhqcew3vzxgt76uh13s62pubgl7?response-content-disposition=inline%3B%20filename%3D%22Untitled%20%2528600%20x%20600%20px%2529%20%2528500%20x%20600%20px%2529%20%2528400%20x%20500%20px%2529%20%2528400%20x%20400%20px%2529%20%25281%2529.png%22%3B%20filename%2A%3DUTF-8%27%27Untitled%2520%2528600%2520x%2520600%2520px%2529%2520%2528500%2520x%2520600%2520px%2529%2520%2528400%2520x%2520500%2520px%2529%2520%2528400%2520x%2520400%2520px%2529%2520%25281%2529.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAT42WQ5V2WCLYNS6F%2F20220726%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220726T093428Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=a2e975511a93da134ff40cbd375a30e530a21de66b389c2eda8596fae764e623",

      // logo: 'https://www.freepnglogos.com/uploads/apple-logo-png/apple-logo-png-transparent-svg-vector-bie-supply-29.png',
    },
    {
      id: 1,
      collapsed: false,
      child_links: [
        { source: 1, target: 2 },
        { source: 1, target: 4 },
      ],
      val: 6,
      type: "rootLevel",
      name: " Category 1",
    },
    {
      id: 2,
      collapsed: false,
      child_links: [
        { source: 2, target: 3 },
      ],
      val: 1,
      type: "level",
      name: "Category 2",
    },
    {
      id: 3,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 3 Company 3 Company 3 Company 3 Company 3 Company 3 Company 3",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 4,
      collapsed: false,
      child_links: [
        { source: 4, target: 23 },
      ],
      val: 3,
      type: "level",
      name: "Category 4",
    },
    {
      id: 5,
      collapsed: false,
      child_links: [
        { source: 5, target: 46 },
        { source: 5, target: 47 },
        { source: 5, target: 52 },
      ],
      val: 9,
      type: "rootLevel",
      name: "Category 5",
    },
    {
      id: 6,
      collapsed: false,
      child_links: [
        { source: 6, target: 40 },
        { source: 6, target: 41 },
      ],
      val: 2,
      type: "level",
      name: "Category 6",
    },
    {
      id: 7,
      collapsed: false,
      child_links: [
        { source: 7, target: 42 },
        { source: 7, target: 43 },
      ],
      val: 2,
      type: "level",
      name: "Category 7",
    },
    {
      id: 9,
      collapsed: false,
      child_links: [
        { source: 9, target: 6 },
        { source: 9, target: 7 },
        { source: 9, target: 11 },
      ],
      val: 9,
      type: "rootLevel",
      name: "Category 9",
    },
    {
      id: 10,
      collapsed: false,
      child_links: [
        { source: 10, target: 30 },
        { source: 10, target: 31 },
      ],
      val: 2,
      type: "level",
      name: "Category 10",
    },
    {
      id: 11,
      collapsed: false,
      child_links: [
        { source: 11, target: 44 },
        { source: 11, target: 45 },
      ],
      val: 2,
      type: "level",
      name: "Category 11",
    },
    {
      id: 12,
      collapsed: false,
      child_links: [
        { source: 12, target: 10 },
        { source: 12, target: 55 },
      ],
      val: 9,
      type: "rootLevel",
      name: "Category 12",
    },
    {
      id: 13,
      collapsed: false,
      child_links: [
        { source: 13, target: 32 },
        { source: 13, target: 33 },
      ],
      val: 2,
      type: "level",
      name: "Category 13",
    },
    {
      id: 14,
      collapsed: false,
      child_links: [
        { source: 14, target: 34 },
        { source: 14, target: 35 },
      ],
      val: 2,
      type: "level",
      name: "Category 14",
    },
    {
      id: 15,
      collapsed: false,
      child_links: [
        { source: 15, target: 36 },
        { source: 15, target: 37 },
      ],
      val: 2,
      type: "level",
      name: "Category 15",
    },
    {
      id: 16,
      collapsed: false,
      child_links: [
        { source: 16, target: 38 },
        { source: 16, target: 39 },
      ],
      val: 2,
      type: "level",
      name: "Category 16",
    },
    {
      id: 17,
      collapsed: false,
      child_links: [
        { source: 17, target: 19 },
        { source: 17, target: 20 },
        { source: 17, target: 21 },
      ],
      val: 3,
      type: "level",
      name: "Category 17",
    },
    {
      id: 18,
      collapsed: false,
      child_links: [
        { source: 18, target: 13 },
        { source: 18, target: 14 },
        { source: 18, target: 15 },
        { source: 18, target: 16 },
        { source: 18, target: 17 },
      ],
      val: 16,
      type: "rootLevel",
      name: "Category 18",
    },
    {
      id: 19,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 19",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 20,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 20",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 21,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 21",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 23,
      collapsed: false,
      child_links: [
        { source: 23, target: 25 },
        { source: 23, target: 26 },
      ],
      val: 2,
      type: "level",
      name: "Category 23",
    },
    {
      id: 25,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 25",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 26,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 26",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 27,
      collapsed: false,
      child_links: [
        { source: 27, target: 28 },
        { source: 27, target: 29 },
      ],
      val: 1,
      type: "level",
      name: "Category 27",
    },
    {
      id: 28,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 28",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 29,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 29",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 30,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 30",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 31,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 31",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 32,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 32",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 33,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 33",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 34,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 34",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 35,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 35",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 36,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 36",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
    },
    {
      id: 37,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 37",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 38,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 38",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
    },
    {
      id: 39,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 39",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
    },
    {
      id: 40,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 40",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
    },
    {
      id: 41,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 41",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
    },
    {
      id: 42,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 42",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 43,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 43",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
    },
    {
      id: 44,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 44",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
    },
    {
      id: 45,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 45",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
    },
    {
      id: 46,
      collapsed: false,
      child_links: [
        { source: 46, target: 48 },
        { source: 46, target: 49 },
      ],
      val: 2,
      type: "level",
      name: "Category 46",
    },
    {
      id: 47,
      collapsed: false,
      child_links: [
        { source: 47, target: 50 },
        { source: 47, target: 51 },
      ],
      val: 2,
      type: "level",
      name: "Category 47",
    },
    {
      id: 48,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 48",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
    },
    {
      id: 49,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 49",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
    },
    {
      id: 50,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 50",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
    },
    {
      id: 51,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 51",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
    },
    {
      id: 52,
      collapsed: false,
      child_links: [
        { source: 52, target: 53 },
        { source: 52, target: 54 },
      ],
      val: 2,
      type: "level",
      name: "Category 52",
    },
    {
      id: 53,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 53",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 54,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 54",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 55,
      collapsed: false,
      child_links: [
        { source: 55, target: 56 },
        { source: 55, target: 57 },
        { source: 55, target: 58 },
        { source: 55, target: 59 },
        { source: 55, target: 60 },
        // { source: 55, target: 61 },
        // { source: 55, target: 62 },
        // { source: 55, target: 63 },
        // { source: 55, target: 64 },
        // { source: 55, target: 65 },
        // { source: 55, target: 66 },
        // { source: 55, target: 67 },
        // { source: 55, target: 68 },
      ],
      val: 5,
      type: "level",
      name: "Category 55",
    },
    {
      id: 56,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 56",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 57,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 57",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 58,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 58",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 59,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 59",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 60,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 60",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 61,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 61",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 62,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 62",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },

    {
      id: 63,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 63",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 64,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 64",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 65,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 65",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },

    {
      id: 66,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 66",
      logo: "https://t4.ftcdn.net/jpg/06/08/88/47/360_F_608884796_XWKYpmmlnvbqpj5zlx9u79ifOW4xwSuh.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 67,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 67",
      logo: "https://as2.ftcdn.net/v2/jpg/01/71/44/85/1000_F_171448518_7rIOgvSb0k1PQtinc9gb5WPOavJjd8Ta.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
    {
      id: 68,
      collapsed: false,
      child_links: [],
      val: 1,
      type: "COMPANY",
      name: "Company 68",
      logo: "https://as2.ftcdn.net/v2/jpg/01/51/78/87/1000_F_151788770_ylI9adsM363GNbBKUPfM7Ttr1WHORfkF.jpg",
      flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    },
  ],
  links: [
    { source: 0, target: 1 },
    { source: 0, target: 5 },
    { source: 0, target: 9 },
    { source: 0, target: 12 },
    { source: 0, target: 18 },
    { source: 1, target: 2 },
    { source: 1, target: 4 },
    { source: 2, target: 3 },
    { source: 5, target: 23 },
    { source: 5, target: 46 },
    { source: 5, target: 47 },
    { source: 5, target: 52 },
    { source: 6, target: 40 },
    { source: 6, target: 41 },
    { source: 7, target: 42 },
    { source: 7, target: 43 },
    { source: 9, target: 6 },
    { source: 9, target: 7 },
    { source: 9, target: 11 },
    { source: 10, target: 30 },
    { source: 10, target: 31 },
    { source: 11, target: 44 },
    { source: 11, target: 45 },
    { source: 12, target: 10 },
    { source: 12, target: 55 },
    { source: 13, target: 32 },
    { source: 13, target: 33 },
    { source: 14, target: 34 },
    { source: 14, target: 35 },
    { source: 15, target: 36 },
    { source: 15, target: 37 },
    { source: 16, target: 38 },
    { source: 16, target: 39 },
    { source: 18, target: 13 },
    { source: 18, target: 14 },
    { source: 18, target: 15 },
    { source: 18, target: 16 },
    { source: 18, target: 17 },
    { source: 17, target: 19 },
    { source: 17, target: 20 },
    { source: 17, target: 21 },
    { source: 23, target: 25 },
    { source: 23, target: 26 },
    { source: 27, target: 28 },
    { source: 27, target: 29 },
    { source: 46, target: 48 },
    { source: 46, target: 49 },
    { source: 47, target: 50 },
    { source: 47, target: 51 },
    { source: 52, target: 53 },
    { source: 52, target: 54 },
    { source: 55, target: 56 },
    { source: 55, target: 57 },
    { source: 55, target: 59 },
    { source: 55, target: 60 },
    // { source: 55, target: 61 },
    // { source: 55, target: 62 },
    // { source: 55, target: 63 },
    // { source: 55, target: 64 },
    // { source: 55, target: 65 },
    // { source: 55, target: 66 },
    // { source: 55, target: 67 },
    // { source: 55, target: 68 },
  ],
};
export { data };
