import React, { useState, useRef, useEffect } from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import MapOverview from "./MapOverview";
import MapSearch from "./MapSearch";
import ForceGraphMap from "./ForceGraphMap";
import axios from "axios";
import styles from "./MapOverview.module.css";
import bag from "./images/bag.svg";
import { data } from "./../config/data";

const store = createStore(rootReducer, applyMiddleware(thunk));

const Ecosystem = ({
  searchPerformed,
  setSearchPerformed,
  initialQuery,
  setInitialQuery,
}) => {
  const [maps, setMaps] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentFlow, setCurrentFlow] = useState("openLevel");
  const [openedLevel, setOpenedLevel] = useState(null);
  const [selectedCompanyNodes, setSelectedCompanyNodes] = useState([]);
  const [isSelectedBySearch, setIsSelectedBySearch] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const mainColor = "red";

  useEffect(() => {
    const calculateInitialMap = () => {
      if (maps.length < 1) {
        return null;
      } else {
        return maps[0];
      }
    };

    setSelectedMap(calculateInitialMap());
  }, [maps]);

  const [selectedMap, setSelectedMap] = useState();
  const selectedId = selectedMap ? selectedMap.id : "";
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchSelect, setIsSearchSelect] = useState(false);
  const [isInputFilled, setIsInputFilled] = useState(false);

  setTimeout(() => {
    setIsSearchSelect(false);
  }, 200);

  const containerRef = useRef(null);

  const selectMap = (map) => {
    setSelectedMap(map);
    containerRef.current.scrollIntoView();
  };

  const getMapData = (id) => {
    setCurrentFlow("openLevel");
    setSelectedCompanyNodes([]);
    setOpenedLevel(null);
    setIsOpen(false);
    setSearchPerformed(false);
    setInitialQuery("");
  };

  const ecosystems_list =
    maps &&
    maps.map((m, index) => (
      <div key={index} onClick={() => getMapData(m.id)}>
        <MapOverview
          key={m.id}
          map={m}
          onMapSelect={selectMap}
          selectedId={selectedId}
          color={mainColor}
        />
      </div>
    ));

  return (
    <Provider store={store}>
      <div>
        {isOpen && <div className={styles.grayBackground}></div>}
        <div className={styles.inline}>
          <div className={styles.wrapper} onClick={() => setIsOpen(!isOpen)}>
            {!isOpen ? <span>CHOOSE MORE MAPS</span> : <span>X</span>}
          </div>
          {isOpen && (
            <div className={styles.ecosystemsList}>{ecosystems_list}</div>
          )}
        </div>
        {selectedMap && (
          <a
            href={`/maps/${selectedMap.slug}/add_company`}
            className={styles.add_company_button}
          >
            <div>
              <img className={styles.bag} src={bag} alt="bag" />
              Add or update your company
            </div>
          </a>
        )}
        <div className="flex justify-center mt-8" ref={containerRef}>
          {true && (
            <div className="space-y-4">
              {data && data.nodes && !isLoading ? (
                <ForceGraphMap
                  setInitialQuery={setInitialQuery}
                  setSearchPerformed={setSearchPerformed}
                  selectedCompany={selectedCompany}
                  selectedCompanyId={companyId}
                  isSearchSelect={isSearchSelect}
                  setIsSearchSelect={setIsSearchSelect}
                  selectedCategories={selectedCategories}
                  selectedCompanyNodes={selectedCompanyNodes}
                  setSelectedCompanyNodes={setSelectedCompanyNodes}
                  openedLevel={openedLevel}
                  setOpenedLevel={setOpenedLevel}
                  currentFlow={currentFlow}
                  setCurrentFlow={setCurrentFlow}
                  data={data}
                  selectedMap={selectedMap}
                  isSelectedBySearch={isSelectedBySearch}
                  setIsSelectedBySearch={setIsSelectedBySearch}
                  isInputFilled={isInputFilled}
                />
              ) : (
                <div className={styles.loading}>Loading...</div>
              )}
            </div>
          )}
        </div>
      </div>
    </Provider>
  );
};

export default Ecosystem;
