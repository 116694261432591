import React from "react"

const CategoryBreadcrumbsDisplay = ({categories, onClick}) => {

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onClick(e);
  }

  return (
    <div className="space-x-1 cursor-pointer hover:bg-gray-100 p-1 -ml-1" onClick={handleClick}>
      {categories.map((c, index) => {
        return (
          <span key={index}>
            <span>{c.title}</span>
            { index < categories.length - 1 &&
              <span>{'>'}</span>
            }
          </span>
        )
      })}
    </div>
  );
}

export default CategoryBreadcrumbsDisplay