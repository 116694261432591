import React from 'react';
import ReactTooltip from 'react-tooltip';
import collapsedMap from './images/collapsedMap.svg';
import expandedMap from './images/expandedMap.svg';
import center from './images/center.svg';
import plus from './images/plus.svg';
import minus from './images/minus.svg';
import './MapToolbarWrap.scss';

const MapToolbar = ({
  handleMapToolbarClick,
  handleZoomIn,
  handleZoomOut,
  handleCenter,
  isOpen,
}) => {
  function collapseAndReCenter() {
    handleMapToolbarClick();
    setTimeout(() => {
      handleCenter();
    }, 1000)
  }

  return (
    <div className="MapToolbarWrap">
      <div
        className="zoomWrap"
        style={{
          height: true ? '80px' : '0',
          paddingTop: true ? '4px' : '0',
          paddingBottom: true ? '4px' : '0',
        }}
      >
        <button className="zoomWrapBtn" onClick={handleZoomIn}>
          <img className="zoomWrapImg" src={plus} alt="plus image" />
        </button>
        <button className="zoomWrapBtn" onClick={handleZoomOut}>
          <img className="zoomWrapImg" src={minus} alt="minus image" />
        </button>
        <button
          className="zoomWrapBtn"
          onClick={handleCenter}
          data-tip
          data-for="recenterTip"
        >
          <img className="zoomWrapImg" src={center} alt="center image" />
        </button>
      </div>

      <button
        className="expandCollapseBtn"
        onClick={collapseAndReCenter}
        data-tip
        data-for="collapseTip"
      >
        {true ? (
          <img className="collapseImg" src={expandedMap} alt="button image" />
        ) : (
          <img className="collapseImg" src={collapsedMap} alt="button image" />
        )}
      </button>

      <ReactTooltip
        id="recenterTip"
        place="left"
        effect="solid"
        backgroundColor="#EF4444"
      >
        <div className="tooltipText">Re-center map</div>
      </ReactTooltip>
      <ReactTooltip
        id="collapseTip"
        place="left"
        effect="solid"
        backgroundColor="#EF4444"
      >
        <div className="tooltipText">Collapse map</div>
      </ReactTooltip>
    </div>
  );
};
export default MapToolbar;
