import logo from './logo.svg';
import './App.css';
import Header from './components/Header';

import Ecosystem from './components/Ecosystem';
import { useState } from 'react';

function App() {
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [initialQuery, setInitialQuery] = useState('');

  return (
    <div>
      <Header setSearchPerformed={setSearchPerformed} setInitialQuery={setInitialQuery}/>
      <Ecosystem 
        searchPerformed={searchPerformed} 
        setSearchPerformed={setSearchPerformed} 
        setInitialQuery={setInitialQuery}
        initialQuery={initialQuery}
      />
    </div>
  );
}

export default App;
